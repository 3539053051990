<template>
  <span>
    <feather-icon
      v-b-tooltip.hover.top="
        $t(
          `Global.${
            $can($acl.action.Update, $acl.subjects.SealDistricts)
              ? 'EditSubject'
              : 'ViewSubject'
          }`,
          { subject: $t('Management.SealDistricts.SealDistrict') }
        )
      "
      :icon="
        $can($acl.action.Create, $acl.subjects.SealDistricts)
          ? 'Edit2Icon'
          : 'EyeIcon'
      "
      size="16"
      class="hover-primary"
      @click="openSideBar"
    />
    <Form
      ref="form"
      :sealDistrict="updatedData"
      :submit-btn-text="`${$t('Global.Save')} ${$t(
        'Management.SealDistricts.SealDistrict'
      )}`"
      :can="$can($acl.action.Create, $acl.subjects.SealDistricts)"
      :header="
        $t('Management.SealDistricts.SealDistrict') + ' ' + $tc('Global.EditSubject')
      "
      @submit="submit"
    />
  </span>
</template>
<script>
import Form from "./form.vue";

export default {
  components: {
    Form,
  },

  props: {
    sealDistrict: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    updatedData: null,
  }),

  methods: {
    openSideBar() {
      this.updatedData = { ...this.sealDistrict };
      this.$refs.form.sidebarVisible = true;
    },
    async submit() {
      try {
        const { data } = await this.$axios.patch(
          `sealdistricts/${this.sealDistrict.id}`,
          this.updatedData
        );

        this.$alert.update(data.name);

        Object.keys(this.updatedData).forEach((key) => {
          this.sealDistrict[key] = this.updatedData[key];
        });

        const { form } = this.$refs;
        form.dirty = false;
        form.sidebarVisible = false;
      } catch {
        this.$alert.error();
      }
    },
  },
};
</script>