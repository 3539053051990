<template>
  <section>
    <b-card no-body>
      <div
        class="custom-search d-flex justify-content-between align-items-center mb-2 px-2 pt-2"
      >
        <h2 class="m-0 font-weight-bolder">
          {{ $t("Global.All") }} {{ $t("Management.SealDistricts.SealDistricts") }}
        </h2>
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
          </div>
          <Create
            v-if="$can($acl.action.Create, $acl.subjects.SealDistricts)"
            @add="addEntry"
          />
        </div>
      </div>

      <Table
        :rows="rows"
        :search-term="searchTerm"
        @delete="deleteRow"
      />
    </b-card>
  </section>
</template>
<script>
import { BFormInput, BCard } from "bootstrap-vue";
import deleteEntry from "@/utility/scripts/delete";
import Create from "./components/create.vue";
import Table from "./components/table.vue";

export default {
  components: {
    BFormInput,
    BCard,
    Create,
    Table,
  },

  data: () => ({
    searchTerm: "",
    rows: [],
  }),

  created() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.loadSealDistricts();
      } catch (error) {
        console.error(error);
      }
    },
    async loadSealDistricts() {
      const { data } = await this.$axios.get("sealdistricts");
      this.rows = data;
    },
    addEntry(entry) {
      this.rows.push(entry);
    },

    async deleteRow(row) {
      try{
        await deleteEntry(this, {
        id: row.id,
        subject: `${this.$t("Management.SealDistricts.SealDistrict")}`,
        article: `${this.$t('Global.Articles_4')}`,
        individual: `"${row.name}"`,
        endpoint: "sealdistricts/",
      });
      this.load();

      } catch (error) {
        // continue regardless of error
      }
    },
  },
};
</script>
