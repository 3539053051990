<template>
    <div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="ml-2 d-flex align-items-center"
        variant="primary"
        @click="openSideBar"
      >
        <feather-icon class="mr-1" icon="MapIcon" size="21" />
        {{ $tc('Global.New', 0) }} {{ $t('Management.SealDistricts.SealDistrict') }}
      </b-button>
      <Form
        ref="form"
        :sealDistrict="sealDistrict"
        :can="$can($acl.action.Create, $acl.subjects.SealDistricts)"
        :header="$tc('Global.New', 0) + ' ' + $t('Management.SealDistricts.SealDistrict')"
        @submit="submit"
        @reset="reset"
      />
    </div>
  </template>
  
  <script>
  import { BButton } from 'bootstrap-vue'
  import Form from './form.vue'
  
  export default {
    components: {
      Form,
      BButton,
    },

    data: () => ({
      sealDistrict: {
        name: null,
      },
    }),
  
    methods: {
      reset() {
        this.sealDistrict = {
          name: null,
        }
      },
      openSideBar() {
        this.$refs.form.sidebarVisible = true
      },
      async submit() {
        try {
          const { data } = await this.$axios.post('sealdistricts', this.sealDistrict)
  
          this.$alert.create(`${data.name}`)
          this.$emit('add', data)
  
          const { form } = this.$refs
          form.dirty = false
          form.sidebarVisible = false
        } catch {
          this.$alert.error()
        }
      },
    },
  }
  </script>
  